import React, { Component } from 'react'
import theme from '../../../styles/theme'
import Contact from '../../Contact'
import Header from '../../Header'
import { OuterContainer, StyledThemeProvider } from '../style'
import { CopyrightContainer, FormContainer, ImageContainer, LoginComponentGroup, LoginLogoContainer, PageWrap } from './style'

const logo = require('../../../assets/images/pages/login/logo.svg')

interface Props {
  children: any
  themeType?: string
  isOpenContactModel?: boolean
  currentPage?: string
}

class LoginLayout extends Component<Props> {
  state = {
    isOpenContactModal: false,
  }

  toogleModal = () => {
    const { isOpenContactModal } = this.state
    this.setState({ isOpenContactModal: !isOpenContactModal })
  }

  render() {
    const { isOpenContactModal } = this.state
    const { children, themeType = 'transparent', currentPage }: Props = this.props
    return (
      <StyledThemeProvider theme={theme}>
        <OuterContainer className="app" id="outer-container" isOpenContactModal={isOpenContactModal}>
          <Header type={themeType} toogleModal={this.toogleModal} currentPage={currentPage} />
          <LoginComponentGroup id="login-component-group" className="row">
            <FormContainer className="col-lg-4 col-md-4">
              <PageWrap id="login-page-wrap">{children}</PageWrap>
              <CopyrightContainer>
                <p>Derechos reservados. Diners Club Ecuador 2019</p>
                <a href="https://medias.grandetable.com.ec/docs/tyc_grande_table.pdf" target="_blank">
                  Términos y condiciones
                </a>
              </CopyrightContainer>
            </FormContainer>
            <ImageContainer className="col-lg-8 col-md-8">
              <LoginLogoContainer src={logo} />
            </ImageContainer>
          </LoginComponentGroup>
          {isOpenContactModal && <Contact toogleModal={this.toogleModal}></Contact>}
        </OuterContainer>
      </StyledThemeProvider>
    )
  }
}

export default LoginLayout
