import React, { Component } from 'react'
import { Link, navigate } from 'gatsby'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import {
  AuthForm,
  FormInputLoginContainer,
  StyledLabel,
  LoginDecorator,
  SubmitButton
} from '../../StylesPages/loginStyle'
import { TitleCard } from '../../StylesPages/membershipStyle'
import { InputContainer, StyledInput, StyledError, CustomButton } from '../../Collapse/stepped/style'
import { ButtonContainer } from '../../Button/style'

class LoginSection extends Component<{ context: any }, any> {
  constructor(props: any) {
    super(props)
    this.state = {
      fields: {},
      errors: {}
    }
  }

  handleUpdate = (field: string, event: any) => {
    let fields = this.state.fields
    fields[field] = event.target.value
    this.setState({ fields })
  }

  handleValidation = () => {
    let fields = this.state.fields
    let errors: any = {}
    let formIsValid = true

    if (!fields['identification']) {
      formIsValid = false
      errors['identification'] = 'Identificación requerida'
    }

    if (!fields['password']) {
      formIsValid = false
      errors['password'] = 'Contraseña requerida'
    }

    this.setState({ errors })
    return formIsValid
  }

  notify = (message: string) => toast(message)

  render() {
    const { errors } = this.state
    const { identification, password } = this.state.fields
    return (
      <AuthForm
        display
        method="post"
        onSubmit={(event: any) => {
          event.preventDefault()
          if (this.handleValidation()) {
            this.props.context
              .handleLogin({ identification, password })
              .then(() => {})
              .catch((error: any) => {
                if (error.response && error.response.data.error === 'invalid_credentials') {
                  this.notify('Usuario o contraseña no inválidos')
                  return
                }
                this.notify('Error de autenticación: ' + error)
              })
          }
        }}
      >
        <TitleCard className="center-title">Iniciar sesión</TitleCard>
        <FormInputLoginContainer>
          <StyledLabel>Número de identificación</StyledLabel>
          <InputContainer>
            <StyledInput
              placeholder="Ingresa tu número de identificación"
              error={errors['identification']}
              name="identification"
              onChange={this.handleUpdate.bind(this, 'identification')}
            />
            {errors['identification'] && <StyledError>{errors['identification']}</StyledError>}
          </InputContainer>
        </FormInputLoginContainer>
        <FormInputLoginContainer>
          <StyledLabel>Contraseña</StyledLabel>
          <InputContainer>
            <StyledInput
              placeholder="Ingresa tu contraseña"
              error={errors['password']}
              type="password"
              name="password"
              onChange={this.handleUpdate.bind(this, 'password')}
            />
            {errors['password'] && <StyledError>{errors['password']}</StyledError>}
          </InputContainer>
        </FormInputLoginContainer>
        <p className="link">
          <Link to={`/forgot-password`}>Olvidé mi contraseña</Link>
        </p>
        <ButtonContainer>
          <SubmitButton type="submit" value="Iniciar sesión" />
        </ButtonContainer>
        <LoginDecorator className="row">
          <div className="line" />
          <div>
            <i className="icon-grande-table-02" />
          </div>
          <div className="line" />
        </LoginDecorator>
        <div className="register-text">¿Todavía no estás registrado? Hazlo aquí</div>
        <ButtonContainer className="secondary-btn-container">
          <CustomButton className="secondary-btn-brown" onClick={() => navigate('/registration')}>
            Registrarme
          </CustomButton>
        </ButtonContainer>

        <ToastContainer
          position="bottom-right"
          autoClose={5000}
          hideProgressBar
          newestOnTop
          closeOnClick
          rtl={false}
          draggable={true}
          pauseOnHover
        />
      </AuthForm>
    )
  }
}
export default LoginSection
