import React, { Component } from 'react'
import { navigate } from 'gatsby'
import LoginLayout from '../components/Layout/Login'
import AuthenticationContext from '../context/auth-context'
import LoginSection from '../components/AuthSection/components/login-section'

class Authentication extends Component {
  render() {
    return (
      <AuthenticationContext.Consumer>
        {(context: any) => {
          if (context.user) {
            navigate(`/`)
          }
          return (
            <LoginLayout
              themeType="transparent"
              currentPage={'login'}
            >
              <LoginSection context={context} />
            </LoginLayout>
          )
        }}
      </AuthenticationContext.Consumer>
    )
  }
}

export default Authentication
