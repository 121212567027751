import styled from 'styled-components'
import theme from '../../../styles/theme'

const BgLoginSmall = require('../../../assets/images/pages/login/rectangle.png')
const BgLogin = require('../../../assets/images/pages/login/rectangle@2x.png')

export const LoginComponentGroup = styled.div`
  height: 100vh;
  &.row {
    margin: 0;
  }
  &.col-md {
    padding: 0;
  }
  @media screen and (max-width: 767px) {
    height: 93vh;
  }
`

export const PageWrap = styled.div``

export const FormContainer = styled.div`
  align-items: center;
  @media screen and (max-width: 767px) {
    order: 2;
  }
`
export const CopyrightContainer = styled.div`
  text-align: center;
  color: #000000;
  font-family: 'San Francisco Display';
  font-weight: 300;
  font-size: 0.9rem;
  letter-spacing: 1.54px;
  padding: 0 0 1rem;
  display: block;
  width: 100%;
  p {
    margin-bottom: 0;
  }
  a {
    background-color: transparent;
    color: #000000;
    text-decoration: underline;
    &:hover {
      color: ${theme.purpleBrown};
    }
  }
  @media screen and (max-width: 450px) {
    position: relative;
    font-size: 0.7rem;
    padding: 1rem 0 1.5rem;
  }
  @media screen and (max-width: 767px) {
    width: 98%;
  }
  @media screen and (min-width: 768px) and (max-width: 1030px) {
    font-size: 0.7rem;
  }
`
export const ImageContainer = styled.div`
  margin-top: -1px;
  align-items: center;
  -webkit-align-items: center;
  -webkit-box-align: center;
  object-fit: contain;
  background-image: url(${BgLogin});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  @media screen and (max-width: 767px) {
    background-color: rgba(0, 0, 0, 0.5);
    background-image: url(${BgLoginSmall});
    order: 1;
    background-blend-mode: darken;
    height: 25%;
  }
`
export const LoginLogoContainer = styled.img`
  object-fit: contain;
  align-self: center;
  margin: 40% 0 0 15%;
  @media screen and (max-width: 380px) {
    margin: 7% 25%;
    width: 50%;
  }
  @media screen and (min-width: 381px) and (max-width: 430px) {
    margin: 4% 26%;
    width: 50%;
  }
  @media screen and (min-width: 431px) and (max-width: 767px) {
    margin: 3% 34%;
    width: 35%;
  }
  @media screen and (min-width: 768px) and (max-width: 1030px) {
    margin: 30% 10%;
    width: 40%;
  }
`
